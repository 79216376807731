:root {

  
  --forza-color-naranja: #ff5100;
  --forza-color-gris: #f3f3f3;
  --forza-color-gris-claro: #d2cdcd;
  --forza-color-gris-oscuro: #4b5f68;
  --forza-color-negro: #1d1d1b;
  --forza-color-gris-header: #f9f9f9;
  --forza-color-azul: #395ba2;
  --forza-color-azul-gris: #5a6d75;
  --forza-color-rojo: #ff3f2f;
  --forza-color-amarillo: #fff7e1;
}
