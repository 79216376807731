:root {
  --ion-font-family: "Poppins", "Open Sans", "San Francisco", Verdana, "Trebuchet MS", sans-serif;
  --ion-main-bar-height: 56px;
  /** primary **/
  --ion-color-primary: #ff5100;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #db4500;
  --ion-color-primary-tint: #000000;

  /** secondary **/
  --ion-color-secondary: #eee;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ff5100;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ddd;
  --ion-color-secondary-tint: #eee;

  /** tertiary **/
  --ion-color-tertiary: #627883;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #3a4c55;
  --ion-color-tertiary-tint: #65808d;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** sky blue outline **/
  --ion-color-sky:  #f4f5f8;
  --ion-color-sky-rgb: 244, 245, 248;
  --ion-color-sky-contrast:rgb( 44,160,247);
  --ion-color-sky-contrast-rgb: 0, 0, 0;
  --ion-color-sky-shade: #d7d8da;
  --ion-color-sky-tint: #f5f6f9;  
}

ion-button {
  --box-shadow: none;
}

.ion-color-sky {
  --ion-color-base: var(--ion-color-sky);
  --ion-color-base-rgb: var(--ion-color-sky-rgb);
  --ion-color-contrast: var(--ion-color-sky-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sky-contrast-rgb);
  --ion-color-shade: var(--ion-color-sky-shade);
  --ion-color-tint: var(--ion-color-sky-tint);
}